<template>
    <div class="assess-result-base">
        <div class="left scrollbar">
            <div class="menu-search">
                <el-input class="search-input" v-model="searchValue" size="mini" clearable @blur="getPgdxList" @clear="getPgdxList" placeholder="请输入关键字"></el-input>
                <div class="search-icon">
                    <i class="el-icon-search" @click="getPgdxList"></i>
                </div>
            </div>
            <div class="menu-content">
                <div v-for="(item,index) in menuList" :key="index">
                    <div class="menu-item" @click="item.open = !item.open">
                        <div>{{item.PGDXLXMC}}</div>
                        <div class="menu-icon" v-if="item.open">
                            <i class="el-icon-caret-top"></i>
                        </div>
                        <div class="menu-icon" v-else>
                            <i class="el-icon-caret-bottom"></i>
                        </div>
                    </div>
                    <div class="menu-item1" :style="{background:item1.ID===currentItemID?'#d1d7ca':''}" v-show="item.open" v-for="(item1,index1) in item.PGDXLIST" :key="index1" @click="handleItem1Click(item1,item)">
                        <div class="menu-label1">{{item1.PGDXMC}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right scrollbar">
            <div class="pgfs-select" v-show="!isDetailShow">
                <div class="pgfs-sel-label">评估方式</div>
                <el-select v-model="pgfsValue" filterable clearable placeholder="请选择评估方式" size="small" @change="getPgjgInfo">
                    <el-option v-for="(item,index) in pgfsList" :key="index" :label="item.Name" :value="item.Code">
                    </el-option>
                </el-select>
                <el-button class="pgfs-sel-btn" size="small" @click="getPgjgInfo">查询</el-button>
            </div>
            <div class="right-content" v-show="!isDetailShow">
                <div v-for="(item,index) in pgjgList" :key="index">
                    <div class="pgjg-item-title">
                        <div>{{item.PGJLMC}}</div>
                        <div class="pgjg-item-title-btn" v-show="item.PGJGLIST.length>3" @click="item.isOpen = !item.isOpen">
                            <span>{{item.isOpen?'收起':'更多'}}</span>
                            <img class="pgjg-item-title-btn-img" :src="item.isOpen?lessImg:moreImg" />
                        </div>
                    </div>
                    <div class="pgjg-item">
                        <div class="pgjg-border1" :style="{maxHeight:Number(item1.PGFS)===0?'160px':Number(item1.PGFS)===1?'340px':Number(item1.PGFS)===2?'190px':'', minHeight:Number(item1.PGFS)===0?'160px':Number(item1.PGFS)===1?'190px':Number(item1.PGFS)===2?'190px':''}" v-for="(item1,index1) in item.PGJGLIST" :key="index1" v-show="item.isOpen?true:index1<3">
                            <div class="pgjg-border1-item1">
                                <div class="border1-item1-label">评估时间段</div>
                                <div>{{item1.RWKSSJ?item1.RWKSSJ.substr(0,10):''}} - {{item1.RWJSSJ?item1.RWJSSJ.substr(0,10):''}}</div>
                                <div class="detail-btn" @click="handleDetailClick(item1)">
                                    <span>详情</span>
                                    <img class="detail-btn-img" src="@image/expert_consultation/arrowRight.png" />
                                </div>
                            </div>
                            <div class="pgjg-border1-item1">
                                <div class="border1-item1-label">评估结果</div>
                                <div class="pgzt-value" v-show="item1.ZTMC" :style="{color: colorArr[Number(item1.ZT)], borderColor:Number(currentItemParent.PGDXLX)===1?colorArr[Number(item1.ZT)]:'rgba(0,0,0,0)',width:Number(currentItemParent.PGDXLX)===1?'60px':'120px',minWidth:Number(currentItemParent.PGDXLX)===1?'60px':'90px',textAlign:Number(currentItemParent.PGDXLX)===1?'center':'left'}" :title="item1.ZTMC">{{item1.ZTMC}}</div>
                                <div :title="item1.PGJG">{{item1.PGJG}}</div>
                            </div>
                            <div class="pgjg-border1-item1">
                                <div class="border1-item1-label">评估方式</div>
                                <div>{{item1.PGFSMC}}</div>
                            </div>
                            <div class="pgjg-border1-item1">
                                <div class="border1-item1-label">评估人</div>
                                <div>{{item1.CJRMC}}</div>
                                <div class="border1-item1-label pgsj-label">评估时间</div>
                                <div class="pgsj-value">{{item1.CJSJ?item1.CJSJ.substr(0,10):''}}</div>
                            </div>
                            
                            <div class="file-item" v-show="Number(item1.PGFS)===1">
                                <div class="border1-item1-label">报告下载</div>
                                <div class="file-border">
                                    <div class="file-border-item" v-for="(item2,index2) in item1.PGBGLIST" :key="index2" :title="item2.MC">
                                        <a class="file-a" :href="item2.LJ" :download="item2.MC" target="_blank">{{item2.MC}}</a>
                                    </div>
                                </div>
                            </div>

                            <div class="pgjg-border1-item1" v-show="Number(item1.PGFS)===2">
                                <div class="border1-item1-label">评估模型</div>
                                <div>
                                    <a>{{item1.PGMX}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-content" v-if="isDetailShow">
                <UndoneDetail></UndoneDetail>
                <el-button class="detail-back-btn" size="mini" @click="handleDetailBackClick">返回</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions,mapMutations } from 'vuex'
import UndoneDetail from '@views/working_desk/analysis_assess/undone-detail.vue'
import { SET_SELECTDATA } from "@store/modules/mutation-type.js";
export default {
    name: 'assess_result_list',
    data() {
        return {
            searchValue: '',
            menuList: [],
            currentItemID: '',
            currentItemParent: {},
            pgfsValue: '',
            pgfsList: [],
            pgjgList: [],
            colorArr: ['#0099ff', '#eecb5f', '#f59a23', '#d9001b', 'green', 'orange', 'pink'],
            isDetailShow: false,
            moreImg: require('@image/expert_consultation/more.png'),
            lessImg: require('@image/expert_consultation/less.png')
        }
    },
    components:{
        UndoneDetail
    },
    mounted() {
        this.getPgdxList();
        this.getPgfsList();
    },
    methods: {
        ...mapActions([
            'GetPgdxListByPgjg', // 根据评估结果信息获取评估对象列表
            'GetEnumItem',
            'GetPgjgInfo', //获取评估结果信息
        ]),
        ...mapMutations({
            SET_SELECTDATA
        }),
        async getPgdxList() {
            this.menuList = [];
            let result = await this.GetPgdxListByPgjg({
                Key: this.searchValue, //String 评估对象名称
            })
            if (result && result.length > 0) {
                for (let i = 0; i < result.length; i++) {
                    if (i === 0) {
                        result[i].open = true;
                        this.handleItem1Click(result[i].PGDXLIST[0], result[i]);
                    } else {
                        if (this.searchValue) {
                            result[i].open = true;
                        } else {
                            result[i].open = false;
                        }
                    }
                    this.menuList.push(result[i]);
                }
            }
        },
        handleItem1Click(val, valParent) {
            if(this.currentItemID === val.ID) return;
            this.currentItemID = val.ID;
            this.currentItemParent = valParent;
            this.getPgjgInfo();
        },
        // 获取评估方式列表
        async getPgfsList() {
            let result = await this.GetEnumItem({
                Enumid: "11a0b052-e2a7-4c84-b0c5-d1befdbcb455"
            });
            this.pgfsList = result;
            this.getPgjgInfo();
        },
        async getPgjgInfo() {
            this.isDetailShow = false;
            this.pgjgList = [];
            if (!this.currentItemID) return;
            let result = await this.GetPgjgInfo({
                Pgdxid: this.currentItemID, //String 评估对象ID
                Pgfs: this.pgfsValue, //String 评估方式
            })
            
            if(result && result.length>0){
                if(result.length === 1){
                    result[0].isOpen = true;
                }else{
                    for(let i = 0; i < result.length; i++){
                        result[i].isOpen = false;
                    }
                }
            }
            this.pgjgList = result;
        },
        handleDetailClick(val){
            this.isDetailShow = true;
            let obj = {
                pgZt: 'done',
            }
            Object.assign(obj, val);
            this.SET_SELECTDATA(obj);
        },
        handleDetailBackClick(){
            this.isDetailShow = false;
        }
    },
}
</script>
<style lang="scss" scoped>
.assess-result-base {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    .left {
        width: 300px;
        min-width: 300px;
        height: calc(100% - 20px);
        margin: 10px 0;
        border-radius: 10px;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        .menu-search {
            width: 100%;
            height: 50px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search-input {
                flex-grow: 1;
            }
            .search-icon {
                width: 30px;
                text-align: right;
                font-size: 20px;
                .el-icon-search {
                    cursor: pointer;
                }
            }
        }
        .menu-content {
            .menu-item {
                height: 32px;
                padding: 0 15px;
                border-top: 1px solid #fff;
                color: #2a3987;
                background: #f7f9f4;
                display: flex;
                align-items: center;
                cursor: pointer;
                .menu-icon {
                    width: 20px;
                    text-align: right;
                }
            }
            .menu-item1 {
                height: 32px;
                line-height: 32px;
                padding: 0 15px 0 30px;
                border-top: 1px solid #eff1f5;
                cursor: pointer;
                .menu-label1 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .menu-item1:hover {
                background: #d1d7ca;
            }
        }
    }
    .right {
        height: 100%;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        .pgfs-select {
            height: 55px;
            margin: 0 0 0 20px;
            border-bottom: 1px solid #d7d7d7;
            display: flex;
            align-items: center;
            .pgfs-sel-label {
                margin-right: 15px;
                color: #23468c;
            }
            .pgfs-sel-btn {
                margin-left: 25px;
                border-radius: 10px;
                background: #2a63d5;
                color: #fff;
            }
            .pgfs-sel-btn:hover {
                font-weight: bold;
            }
        }
        .right-content {
            padding: 10px 20px;
            .pgjg-item-title {
                height: 40px;
                margin-top: 20px;
                color: #364a7a;
                font-size: 18px;
                display: flex;
                align-items: center;
                .pgjg-item-title-btn {
                    height: 27px;
                    line-height: 27px;
                    margin-left: 20px;
                    padding: 0 14px;
                    border-radius: 15px;
                    font-size: 12px;
                    background: #d1d7ca;
                    cursor: pointer;
                    .pgjg-item-title-btn-img {
                        vertical-align: middle;
                        margin-top: -2px;
                        margin-left: 8px;
                    }
                }
            }
            .pgjg-item {
                position: relative;
                display: flex;
                flex-flow: row wrap;
                .pgjg-border1 {
                    width: calc(33% - 20px);
                    min-width: 400px;
                    margin: 10px 20px 10px 0;
                    padding: 20px;
                    border-radius: 10px;
                    font-size: 12px;
                    background: #fff;
                    .pgjg-border1-item1 {
                        position: relative;
                        height: 30px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        .border1-item1-label {
                            width: 90px;
                            min-width: 90px;
                            padding: 0 20px 0 0;
                            text-align: right;
                            color: #aaaaaa;
                        }
                        .detail-btn {
                            position: absolute;
                            right: 0;
                            width: 70px;
                            height: 22px;
                            line-height: 22px;
                            padding: 0 8px;
                            text-align: center;
                            border-radius: 15px;
                            font-size: 12px;
                            background: #f7f9f4;
                            cursor: pointer;
                            .detail-btn-img {
                                vertical-align: middle;
                                margin-top: -2px;
                                margin-left: 8px;
                            }
                        }
                        .pgzt-value {
                            width: 60px;
                            min-width: 60px;
                            height: 20px;
                            line-height: 18px;
                            margin-right: 20px;
                            padding: 0 2px;
                            text-align: center;
                            border: 1px solid;
                            border-radius: 6px;
                        }
                        .pgsj-label {
                            position: absolute;
                            right: 70px;
                        }
                        .pgsj-value {
                            position: absolute;
                            right: 0;
                            text-align: right;
                            width: 80px;
                        }
                        .file-border {
                            height: 26px;
                            line-height: 26px;
                            .file-a,
                            .file-a:active,
                            .file-a:visited {
                                text-decoration: underline;
                                color: #0076ff;
                            }
                        }
                    }
                    .pgjg-border1-item1>div,
                    .file-item>div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .file-item{
                        position: relative;
                        line-height: 30px;
                        .border1-item1-label {
                            width: 90px;
                            min-width: 90px;
                            padding: 0 20px 0 0;
                            text-align: right;
                            color: #aaaaaa;
                        }
                        .file-border{
                            margin-left: 90px;
                            margin-top: -28px;
                            .file-border-item {
                                max-width: 326px;
                                height: 26px;
                                line-height: 26px;
                                margin-bottom: 10px;
                                padding: 0 10px;
                                border-radius: 6px;
                                background: #e2e8db;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                .file-a,
                                .file-a:active,
                                .file-a:visited {
                                    text-decoration: none;
                                    color: #2a8748;
                                }
                                .file-a:hover{
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
            .detail-back-btn{
                position: absolute;
                top:-35px;
                right:20px;
            }
        }
    }
}
</style>
